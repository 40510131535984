$primary: #9BB850;
// $primary: #5681B8;
$secondary: #6344EB;
$dark: #111827;
$light: #FFFFFF;
$success: #A1B844;
// $success: #A1B844;
$warning: #EB8C44;

$body-bg: #F9FAFC;

$font-family-sans-serif: "Roboto", "Inter", "Open Sans", "sans-serif" !default;

$h1-font-size: 4rem;
