.navbar {
  .navbar__right-section {
    .navbar-nav {
      li.nav-item + li.nav-item {
        margin-left: 10px;
      }

      .dropdown-menu {
        position: absolute;
        left: initial;
        right: 0;
        top: 50px;
      }
    }
  }
}
