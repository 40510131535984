@import '../../../../assets/styles/variables';

.sidebar {
  width: 25%;
  max-width: 350px;

  .sidebar__nav-item {
    width: 100%;

    a:not(.btn--with-icon) {
      display: block;
    }

    a, button {
      color: white;
      font-weight: 500;
      text-decoration: none;
      height: 100%;
      width: 100%;
      padding: 5px 10px;

      &.active {
        color: $primary;
      }
    }

    &:hover {
      background-color:#444052;
      border-radius: 5px;

      a, button {
        color: $primary;
      }
    }
  }
}
